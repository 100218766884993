import { ApolloClient, InMemoryCache } from '@apollo/client';

import customConfig from '../../../config';

const apolloClient = new ApolloClient({
  uri: `${customConfig.graphqlServer}/pos-public`,
  cache: new InMemoryCache(),
  headers: {
    'X-Mock-Services': String(customConfig.graphqlGatsbyServerMocked === 'true')
  }
});

export default apolloClient;
