import React, { createContext, FunctionComponent, useContext } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import { environmentName } from '../../../config';

export interface LogPropsType {
  type: 'debug' | 'info' | 'warn' | 'error';
  message: string;
  data?: any;
}

export interface LogsContextType {
  log: (props: LogPropsType) => void;
}

const LogsContext = createContext({} as LogsContextType);
const useLogs = () => useContext(LogsContext);

const initDatadog = (): void => {
  datadogLogs.init({
    clientToken: global.config.gatsbyDatadogClientToken as string,
    service: 'raisin-pre-login',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: environmentName
  });
};

const LogsProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  initDatadog();

  const log = ({ type, message, data = {} }: LogPropsType): void => {
    switch (type) {
      case 'debug': {
        return datadogLogs.logger.debug(message, data);
      }
      case 'info': {
        return datadogLogs.logger.info(message, data);
      }
      case 'warn': {
        return datadogLogs.logger.warn(message, data);
      }
      case 'error': {
        return datadogLogs.logger.error(message, data);
      }
      // no default
    }
  };

  return <LogsContext.Provider value={{ log }}>{children}</LogsContext.Provider>;
};

export default LogsContext;

export { LogsProvider, useLogs };
