/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import PropTypes from 'prop-types';

import { exponea, snowplow } from '@savebetter/tracking-events';
import { trackPageViewEvent } from '@savebetter/tracking-events/lib/snowplow';

import 'whatwg-fetch';

import customConfig from '../config';

import { LogsProvider } from './src/context/LogsContext';
import client from './src/graphql/client';

import '../components/build/main.css';
import './src/styles/global.css';

global.config = customConfig;

exponea.initializeTracking();
snowplow.initializeTracking();

export const onRouteUpdate = () => {
  trackPageViewEvent();
};

export const wrapRootElement = ({ element }) => (
  <LogsProvider>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </LogsProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node
};
